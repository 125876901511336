import React from "react"
import CellPhoneIcon from "mdi-react/CellPhoneIcon"

import CashIcon from "mdi-react/CashIcon"
  

import PageBody from "../../components/page-body/pageBody"
import SEO from "../../components/seo"

import Banner from "../../components/banner/banner"
import foodBank from "../../images/components/food-bank.jpg"
import foodBankBody1 from "../../images/components/food-bank-body-1.png"

import BorderTitle from "./../../components/border-title/borderTitle"
import ComponentNavigator from "./../../components/component-navigator/componentNavigator"
import QuoteImages from "./../../components/quote-images/quoteImages"

import "./church-based-food-pantry-program.scss"

var ChurchBasedFoodPantryProgram = props => (
  <>
    <SEO title="Home" keywords={["gatsby", "application", "react"]} />
    <div className="church-based-food-pantry-program-page">
      <Banner
        src={foodBank}
        title="Church-Based Food Pantry Program"
        height="32.5rem"
        heightMobile="120vw"
        backgroundPosY="center"
      />
      <PageBody className="page">
        <BorderTitle>
          Church-Based Food Pantry Program
        </BorderTitle>
        <div className="body">
          <img className="left" src={foodBankBody1} alt="" /><p>For our target communities, church plays a large role in their lives.  It is the place where people can connect with others and connect with their faith.  As such, we have partnered with select churches to open food pantries so our participants will have access to healthy foods, while keeping close to their social connections.  This is especially important as our participants live in an area that is known to have food deserts, which are areas that have little access to places to buy affordable healthy food.</p><p>Our food pantries provide fresh fruits and vegetables, along with whole grains and hearty breakfast foods.</p>
        </div>
        
        <div className="navigation">
        <ComponentNavigator
            link="/components-year-two/financial-literacy-and-asset-security-program-for-family-members"
            className="first"
            icon={CashIcon}
            title="Financial Literacy and Asset Security Program for Family Members"
            />
          <ComponentNavigator
            next
            link="/components-year-two/mobile-phone-use-training-for-seniors"
            icon={CellPhoneIcon}
            title="Mobile Phone-Use Training for Seniors"
          />
        </div>
      </PageBody>
    </div>
  </>
  )

export default ChurchBasedFoodPantryProgram
